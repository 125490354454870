import React from "react";
import { Helmet } from "react-helmet";
import AboutHome from "../ui-containers/about-home/AboutHome";
import Banner from "../ui-containers/banner/Banner";
import Brand from "../ui-containers/brand/Brand";
import FunFact from "../ui-containers/fun-fact/FunFact";
import Gallery from "../ui-containers/gallery/Gallery";
import Help from "../ui-containers/help/Help";
import Services from "../ui-containers/services/Services";

const Home = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com" />
        <meta name="description" content="Home page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Home page, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <Banner />
      <Services />
      <Help />
      {/* <Team /> */}
      <AboutHome />
      <Brand />
      <Gallery />
      <FunFact />
      {/* <Blog /> */}
      {/* <Testimonial /> */}
    </main>
  );
};

export default Home;
