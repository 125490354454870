import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import BreadcrumbBG from "../ui-containers/layout/breadcrumb-bg/BreadcrumbBG";

const Blogs = () => {
  // const { blogId } = useParams();
  return (
    <div className="page-default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com/blogs" />
        <meta name="description" content="Blog page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Blog page, logistics service, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <BreadcrumbBG name="Blog Details" />
      <div className="postbox-area postbox-area-padd  pt-120 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="postbox__wrapper mb-60">
                <article className="postbox__item format-image mb-55">
                  <div className="postbox__thumb w-img mb-35">
                    <img src="/assets/img/blog/blog-page-img-1.jpg" alt="blog-thumg" />
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-10">
                      <div className="blog__meta">
                        <span>
                          <NavLink>
                            <i className="fas fa-user"></i> Hunhu La.
                          </NavLink>
                        </span>
                        <span>
                          <i className="fas fa-alarm-clock"></i> Aug 10,2022
                        </span>
                      </div>
                    </div>
                    <div className="section__title mb-20">
                      <h3 className="title-sm">Need an emergency car service.</h3>
                    </div>
                    <div className="postbox__text mb-30">
                      <p className="content-para">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                        doloremque laudantium, aperiam ipsquae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta sunt explicabo. Nemo enim voluptatem voluptas
                        sit aspernatur aut odit aut fugit.
                      </p>
                      <p className="mb-30">
                        Nancy boy Charles down the pub get stuffed mate easy peasy brown bread car
                        boot squiffy loo, blimey arse over tit it’s your round cup of char horse
                        play chimney pot old. Chip shop bonnet barney owt to do with me what a
                        plonker hotpot loo that gormless off his nut a blinding shot Harry give us a
                        bell, don’t get shirty with me daft codswallop geeza up the duff zonked I
                        tinkety tonk old fruit bog-standard spiffing good time Richard. Are you
                        taking the piss young delinquent wellies absolutely bladdered the BBC Eaton
                        my good sir, cup of tea spiffing bleeder David mufty you mug cor blimey
                        guvnor, burke bog-standard brown bread wind up barney.
                      </p>
                      <blockquote>
                        <p>
                          Tosser argy-bargy mush loo at public school Elizabeth up the duff buggered
                          chinwag on your bike mate don’t get shirty with me super, Jeffrey bobby
                          Richard cheesed off spend a penny a load of old tosh blag horse.
                        </p>
                        <p>
                          <cite>Hasibul Hossen</cite>
                        </p>
                      </blockquote>
                      <p className="mb-25">
                        Cheeky bugger cracking goal starkers lemon squeezy lost the plot pardon me
                        no biggie the BBC burke gosh boot so I said wellies, zonked a load of old
                        tosh bodge barmy skive off he legged it morish spend a penny my good sir
                        wind up hunky-dory. Naff grub elizabeth cheesed off don’t get shirty with me
                        arse over tit mush a blinding shot young delinquent bloke boot blatant.
                      </p>
                      <div className="postbox__thumb w-img mb-30">
                        <img src="/assets/img/blog/blog-page-img-3.jpg" alt="blog-thumg" />
                      </div>
                      <div className="section__title mb-20">
                        <h3 className="title-sm">Web Design Done Well Perfectly Pointless </h3>
                      </div>
                      <p className="content-para">
                        Are you taking the piss young delinquent wellies absolutely bladdered the
                        Eaton my good sir, cup of tea spiffing bleeder David mufty you mug cor
                        blimey guvnor, burke bog-standard brown bread wind up barney. Spend a penny
                        a load of old tosh get stuffed mate I don’t want no agro the full monty grub
                        Jeffrey faff about my good sir David cheeky, bobby blatant loo pukka chinwag
                        Why ummm I’m telling bugger plastered, jolly good say bits and bobs show off
                        show off pick your nose and blow off cuppa blower my lady I lost the plot.
                      </p>
                      <p className="mb-45">
                        Cheeky bugger cracking goal starkers lemon squeezy lost the plot pardon me
                        no biggie the BBC burke gosh boot so I said wellies, zonked a load of old
                        tosh bodge barmy skive off he legged it morish spend a penny my good sir
                        wind up hunky-dory. Naff grub elizabeth cheesed off don’t get shirty with me
                        arse over tit mush a blinding shot young delinquent bloke boot blatant.
                      </p>
                      <div className="tagcloud details">
                        <div className="section__title mb-25">
                          <h3 className="title-sm">Post Tags :</h3>
                        </div>
                        <NavLink>Covid-19</NavLink>
                        <NavLink>Gene</NavLink>
                        <NavLink>Test</NavLink>
                        <NavLink>Lab</NavLink>
                        <NavLink>data</NavLink>
                      </div>
                    </div>
                  </div>
                </article>
                <div className="blog__author mb-55 d-sm-flex postbox__shadow">
                  <div className="blog__author-img mb-30 mr-30">
                    <img src="/assets/img/blog/author.jpg" className="img-fluid" alt="img" />
                  </div>
                  <div className="blog__author-content mb-30">
                    <h5>David Warner</h5>
                    <span>Author</span>
                    <p>
                      I said cracking goal down the pub blag cheeky bugger at public school A bit of
                      how's your father boot.!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog__sidebar-wrapper mb-60">
                <div className="sidebar__widget mb-40">
                  <div className="sidebar__widget-content">
                    <h3 className="sidebar__widget-title mb-25">Search</h3>
                    <div className="sidebar__search">
                      <form action="#">
                        <div className="single-input-field">
                          <input name="name" type="text" placeholder="Search Here" />
                          <i className="fas fa-search"></i>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="sidebar__widget mb-40">
                  <h3 className="sidebar__widget-title mb-25">Category</h3>
                  <div className="sidebar__widget-categories">
                    <ul>
                      <li>
                        <NavLink>
                          Chemistry<span>03</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          Forensic science <span>07</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          Gemological <span>09</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          Covid Analysis <span>01</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          Becteriology <span>00</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          Angiosperm <span>26</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar__widget mb-40">
                  <h3 className="sidebar__widget-title mb-25">Recent Post</h3>
                  <div className="sidebar__widget-content">
                    <div className="sidebar__post">
                      <div className="rc__post d-flex mb-20">
                        <div className="rc__post-thumb">
                          <NavLink>
                            <img src="/assets/img/blog/b-1.jpg" alt="blog-sidebar" />
                          </NavLink>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>4 March. 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <NavLink>Don't Underestimate Tree for Medicine</NavLink>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post d-flex mb-20">
                        <div className="rc__post-thumb">
                          <NavLink>
                            <img src="/assets/img/blog/b-2.jpg" alt="blog-sidebar" />
                          </NavLink>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>12 February. 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <NavLink>Equipping Researchers in the Developing World</NavLink>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post d-flex mb-20">
                        <div className="rc__post-thumb">
                          <NavLink>
                            <img src="/assets/img/blog/b-3.jpg" alt="blog-sidebar" />
                          </NavLink>
                        </div>
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>14 January. 2022</span>
                          </div>
                          <h3 className="rc__post-title">
                            <NavLink>Role of Genetics in treating Heigh-grade glioma</NavLink>
                          </h3>
                        </div>
                      </div>
                      <div className="rc__post d-flex">
                        <div className="rc__post-content">
                          <div className="rc__meta">
                            <span>18 March. 2021</span>
                          </div>
                          <h3 className="rc__post-title">
                            <NavLink>Research And Verify of a Covid-19 Virus</NavLink>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar__widget">
                  <h3 className="sidebar__widget-title mb-25">Popular Tag</h3>
                  <div className="sidebar__widget-content">
                    <div className="tagcloud">
                      <NavLink>Covid-19</NavLink>
                      <NavLink>Gene</NavLink>
                      <NavLink>Test</NavLink>
                      <NavLink>Lab</NavLink>
                      <NavLink>data</NavLink>
                      <NavLink>Virus</NavLink>
                      <NavLink>Pandemic </NavLink>
                      <NavLink>Symptoms</NavLink>
                      <NavLink>Medicine</NavLink>
                      <NavLink>Bacteria</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
