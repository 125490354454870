import React from "react";

const Brand = () => {
  return (
    <div className="brand white-bg two border-tb">
      <div
        className="container-fluid p-0 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay=".3s"
      >
        <div className="swiper-container brand-active-2">
          <div className="swiper-wrapper text-center">
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc1.png" alt="Brand" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc2.png" alt="Brand" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc3.png" alt="Brand" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc4.png" alt="Brand" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc5.png" alt="Brand" />
              </div>
            </div>
            <div className="swiper-slide">
              <div className="brand-items-2">
                <img src="/assets/img/brand/bc6.png" alt="Brand" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
