import ROUTES from "../../routes/constants";

export const NavItems = [
  { link: ROUTES.PATHS.HOME, title: "Home" },
  { link: ROUTES.PATHS.ABOUT, title: "About" },
  { link: ROUTES.PATHS.CAR_SHIPPING, title: "Car Shipping" },
  { link: ROUTES.PATHS.SERVICES, title: "Services" },
  { link: ROUTES.PATHS.CONTACT, title: "Contact" },
];

export const ELG_PHONE = "(833) ELG - SHIP";
export const ELG_EMAIL = "sales@efficiencyfreight.com";
export const ELG_ONBOARDING =
  "https://elgonboarding.rmissecure.com/_s/reg/GeneralRequirementsV2.aspx";
