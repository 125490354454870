import React from "react";

const AboutApproach = () => {
  return (
    <section className="approach__area fix grey-bg-4">
      <div className="approach__img m-img">
        <img src="/assets/img/approach/approch-img.jpg" alt="approach" />
      </div>
      <div className="container">
        <div className="row g-0 justify-content-end">
          <div className="col-lg-6">
            <div
              className="approach__content wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <div className="section__title mb-35">
                <span className="sub-title">approach</span>
                <h2 className="title">100% Satisfaction Guarantee</h2>
              </div>
              <div className="approach__text">
                <p>
                  Efficiency Logistics Group is a representative logistics operator providing full
                  range of service in the sphere of customs clearance transportation worldwide for
                  any cargo.
                </p>
                <ul>
                  <li>
                    <i className="fal fa-check-circle" />
                    Commercial expertise
                  </li>
                  <li>
                    <i className="fal fa-check-circle" />
                    Logistical expertise
                  </li>
                  <li>
                    <i className="fal fa-check-circle" />
                    Sustainability goals
                  </li>
                  <li>
                    <i className="fal fa-check-circle" />
                    Cost Optimization
                  </li>
                  <li>
                    <i className="fal fa-check-circle" />
                    Reduce Transit Time
                  </li>
                  <li>
                    <i className="fal fa-check-circle" />
                    Managing Logistics
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutApproach;
