import axios from "axios";
import { toast } from "react-toastify";

let headers = {};

const api = axios.create({
  baseURL: "https://logistic-api.vercel.app/api/",
  headers,
});

api.interceptors.request.use(
  async (config) => {
    /* config.headers.Authorization = `Bearer ${key.accessToken}`; */
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    /* console.log("InterceptorResponse ", response) */
    return Promise.resolve(response.data);
  },
  (error) => {
    /* console.error('InterceptorError ', error.response.status, ":", error.message); */
    toast.error("Oops! Something went wrong.");
    return Promise.reject(error);
  }
);

export default api;
