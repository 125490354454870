const HOME = "/";
const ABOUT = "/about";
const CAR_SHIPPING = "/car-shipping";
const SERVICES = "/services";
const BLOGS = "/blogs";
const CONTACT = "/contact";
const GET_A_QUOTE = "/request-a-quote";
const BLOG_DETAILS = `${BLOGS}/:blogId`;

const ROUTES = {
  PATHS: {
    HOME,
    ABOUT,
    CAR_SHIPPING,
    SERVICES,
    CONTACT,
    BLOGS,
    GET_A_QUOTE,
    BLOG_DETAILS,
  },
};

export default ROUTES;
