import React from "react";
import { ELG_EMAIL } from "../../shared/constants/const";

const AboutMission = () => {
  return (
    <section className="mission__area p-relative fix grey-bg-4 mb-120">
      <div className="mission__img m-img">
        <img src="/assets/img/mission/mission-img.jpg" alt="mission" />
      </div>
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-6">
            <div
              className="mission__content wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <div className="section__title mb-35">
                <span className="sub-title">Our Mission</span>
                <h2 className="title">Our global logistics expertise supply</h2>
              </div>
              <div className="mission__text">
                <p>
                  Efficiency Logistics Group is a representative logistics operator providing full
                  range of service in the sphere of customs clearance transportation worldwide for
                  any cargo.
                </p>
                <div className="mission__text-inner">
                  <img src="/assets/img/mission/mission-contact-img.jpg" alt="mission" />
                  <div className="mission__text-contact">
                    <div className="single-contact-info d-flex align-items-center">
                      <div className="contact-info-icon">
                        <a href="#">
                          <i className="flaticon-envelope" />
                        </a>
                      </div>
                      <div className="contact-info-text">
                        <span>send email</span>
                        <h5>
                          <a href={`mailto:${ELG_EMAIL}`}>
                            <span
                              className="__cf_email__"
                              data-cfemail="ea83848c85aa9d8f888e859dc4898587"
                            >
                              {ELG_EMAIL}
                            </span>
                          </a>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
