import React from "react";
import { NavLink } from "react-router-dom";
import backgroundUrl from "../../helpers/backgroundUrl";
import ROUTES from "../../routes/constants";

const Banner = () => {
  return (
    <div className="banner-area banner-area3 pos-rel">
      <div className="swiper-container slider__active-3">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="single-banner banner-overlay-3 single-banner-3 d-flex align-items-center banner-840">
              <div
                className="banner-bg banner-bg3"
                style={backgroundUrl("/assets/img/slider/slider-5.jpg")}
              ></div>
              <div className="container pos-rel">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-12 col-lg-8 col-md-9">
                    <div className="banner-content banner-content3 text-center">
                      <div
                        className="banner-meta-text bdevs-el-subtitle"
                        data-animation="fadeInUp"
                        data-delay=".3s"
                      >
                        <span>Welcome to Efficiency Logistics Group</span>
                      </div>
                      <h1
                        className="banner-title bdevs-el-title"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        Transport Anything <br /> From Anywhere.
                      </h1>
                      <div
                        className="banner-btn justify-content-center"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        <NavLink
                          to={ROUTES.PATHS.GET_A_QUOTE}
                          className="fill-btn clip-btn bdevs-el-btn"
                        >
                          Get a Quote
                        </NavLink>
                        <NavLink to={ROUTES.PATHS.SERVICES} className="skew-btn bdevs-el-sec-btn">
                          Our Services
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-content3-round1 vert-move"></div>
              <div className="banner-content3-round2 hori-move"></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="single-banner banner-overlay-3 single-banner-3 d-flex align-items-center banner-840">
              <div
                className="banner-bg banner-bg3"
                style={backgroundUrl("/assets/img/slider/slider-6.jpg")}
              ></div>
              <div className="container pos-rel">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-12 col-lg-8 col-md-9">
                    <div className="banner-content banner-content3 text-center">
                      <div
                        className="banner-meta-text bdevs-el-subtitle"
                        data-animation="fadeInUp"
                        data-delay=".3s"
                      >
                        <span>Welcome to Efficiency Logistics Group</span>
                      </div>
                      <h1
                        className="banner-title bdevs-el-title"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        Car transportation <br /> From us.
                      </h1>
                      <div
                        className="banner-btn justify-content-center"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        <NavLink
                          to={ROUTES.PATHS.GET_A_QUOTE}
                          className="fill-btn clip-btn bdevs-el-btn"
                        >
                          Get a Quote
                        </NavLink>
                        <NavLink to={ROUTES.PATHS.SERVICES} className="skew-btn bdevs-el-sec-btn">
                          Our Services
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-content3-round1 vert-move"></div>
              <div className="banner-content3-round2 hori-move"></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="single-banner banner-overlay-3 single-banner-3 d-flex align-items-center banner-840">
              <div
                className="banner-bg banner-bg3"
                style={backgroundUrl("/assets/img/slider/slider.png")}
              ></div>
              <div className="container pos-rel">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-12 col-lg-8 col-md-9">
                    <div className="banner-content banner-content3 text-center">
                      <div
                        className="banner-meta-text bdevs-el-subtitle"
                        data-animation="fadeInUp"
                        data-delay=".3s"
                      >
                        <span>Welcome to Efficiency Logistics Group</span>
                      </div>
                      <h1
                        className="banner-title bdevs-el-title"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        Air transportation <br /> From us.
                      </h1>
                      <div
                        className="banner-btn justify-content-center"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        <NavLink
                          to={ROUTES.PATHS.GET_A_QUOTE}
                          className="fill-btn clip-btn bdevs-el-btn"
                        >
                          Get a Quote
                        </NavLink>
                        <NavLink to={ROUTES.PATHS.SERVICES} className="skew-btn bdevs-el-sec-btn">
                          Our Services
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-content3-round1 vert-move"></div>
              <div className="banner-content3-round2 hori-move"></div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="single-banner banner-overlay-3 single-banner-3 d-flex align-items-center banner-840">
              <div
                className="banner-bg banner-bg3"
                style={backgroundUrl("/assets/img/slider/slider-4.jpg")}
              ></div>
              <div className="container pos-rel">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-12 col-lg-8 col-md-9">
                    <div className="banner-content banner-content3 text-center">
                      <div
                        className="banner-meta-text bdevs-el-subtitle"
                        data-animation="fadeInUp"
                        data-delay=".3s"
                      >
                        <span>Welcome to Efficiency Logistics Group</span>
                      </div>
                      <h1
                        className="banner-title bdevs-el-title"
                        data-animation="fadeInUp"
                        data-delay=".5s"
                      >
                        get the best services <br /> From us.
                      </h1>
                      <div
                        className="banner-btn justify-content-center"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        <NavLink
                          to={ROUTES.PATHS.GET_A_QUOTE}
                          className="fill-btn clip-btn bdevs-el-btn"
                        >
                          Get a Quote
                        </NavLink>
                        <NavLink to={ROUTES.PATHS.SERVICES} className="skew-btn bdevs-el-sec-btn">
                          Our Services
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-content3-round1 vert-move"></div>
              <div className="banner-content3-round2 hori-move"></div>
            </div>
          </div>
        </div>
        <div className="slider-nav">
          <div className="slider-button-prev">
            <i className="fal fa-long-arrow-left"></i>
          </div>
          <div className="slider-button-next">
            <i className="fal fa-long-arrow-right"></i>
          </div>
        </div>
        {/* <div className="slider-pagination slider1-pagination"></div> */}
      </div>
    </div>
  );
};

export default Banner;
