import React from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes/constants";

const AboutHome = () => {
  return (
    <section
      className="about__area-2 pt-120 wow fadeInUp"
      data-wow-duration="1.5s"
      data-wow-delay=".3s"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-xl-5">
            <div
              className="about__content-2 mb-20 wow fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay=".5s"
            >
              <div className="section__title mb-50">
                <span className="sub-title">about us</span>
                <h2 className="title">
                  A company involved in <br /> servicing, maintenance.
                </h2>
              </div>
              <div className="about__content-tab-2 mt-40">
                <ul className="nav mb-5" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active clip-lg-btn"
                      id="approch-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#approch"
                      type="button"
                      role="tab"
                      aria-controls="approch"
                      aria-selected="true"
                    >
                      our approach
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link clip-lg-btn"
                      id="goal-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#goal"
                      type="button"
                      role="tab"
                      aria-controls="goal"
                      aria-selected="false"
                    >
                      our goal
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link clip-lg-btn"
                      id="mision-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mision"
                      type="button"
                      role="tab"
                      aria-controls="mision"
                      aria-selected="false"
                    >
                      our mision
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="approch"
                    role="tabpanel"
                    aria-labelledby="approch-tab"
                  >
                    <p>
                      Utilizing technology and experienced personnel to provide efficient and
                      cost-effective transportation solutions, using optimization techniques, while
                      maintaining open communication with clients.
                    </p>
                    <div className="about__content-tab-btn mt-35">
                      <NavLink className="fill-btn clip-md-btn" to={ROUTES.PATHS.ABOUT}>
                        learn more
                      </NavLink>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="goal"
                    role="tabpanel"
                    aria-labelledby="goal-tab"
                  >
                    <p>
                      To consistently exceed our clients' expectations by providing reliable and
                      high-quality logistics services. We strive to improve our operations and
                      processes for the best service possible.
                    </p>
                    <div className="about__content-tab-btn mt-35">
                      <NavLink className="fill-btn clip-md-btn" to={ROUTES.PATHS.ABOUT}>
                        learn more
                      </NavLink>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="mision"
                    role="tabpanel"
                    aria-labelledby="mision-tab"
                  >
                    <p>
                      To be the leading freight broker in the industry, by providing unparalleled
                      service, building long-term partnerships with clients, constantly developing
                      our team for the highest standards of logistics service.
                    </p>
                    <div className="about__content-tab-btn mt-35">
                      <NavLink className="fill-btn clip-md-btn" to={ROUTES.PATHS.ABOUT}>
                        learn more
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-7">
            <div
              className="about__img-2 mb-60 ml-60 w-img p-relative wow fadeInLeft"
              data-wow-duration="1.5s"
              data-wow-delay=".3s"
            >
              <img src="/assets/img/about/about-us-2.png" alt="About" />
              <div className="about__btn-2">
                <a
                  href="https://www.youtube.com/watch?v=5Gsam6jyRI0"
                  className="popup-video play-btn play-btn-white"
                >
                  <i className="fas fa-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
