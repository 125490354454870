import React from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes/constants";

const AboutDetails = () => {
  return (
    <section
      className="about__3 about__gray-bg p-relative pt-120 pb-60 wow fadeInUp"
      data-wow-duration="1.5s"
      data-wow-delay=".3s"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="about__3-img-wrapper p-relative mb-60">
              <div className="about__3-top w-img">
                <img src="/assets/img/about/about-3-1.png" alt="About" />
              </div>
              <div className="about__3-main w-img">
                <img src="/assets/img/about/about-page-1.jpg" alt="About" />
              </div>
              <div className="about__3-text clip-box-sm">
                <span>
                  <i className="far fa-trophy-alt" />
                </span>
                <h4 className="about__3-title">25 Years of experience</h4>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="about__3-content mb-60">
              <div className="section__title mb-30">
                <span className="sub-title">about us</span>
                <h2 className="title">
                  A company involved in <br /> servicing, maintenance.
                </h2>
              </div>
              <div className="about__3-content-inner p-relative">
                <div className="about__3-content-left">
                  <p>
                    We are a logistics service provider and freight broker company, specialized in
                    arranging transportation for clients' goods using a network of carriers. Our
                    team of professionals finds the best cost-effective and efficient transportation
                    solutions for clients, with a wide range of carrier partnerships to handle any
                    type of freight, including LTL, FTL, and oversized loads.
                  </p>
                  <p>
                    We use cutting-edge technology to track and monitor shipments, ensuring that
                    they are delivered on time and in good condition.
                  </p>
                  <div className="about__3-content-btn mt-35">
                    <NavLink to={ROUTES.PATHS.CONTACT} className="skew-btn">
                      Contact Us
                    </NavLink>
                  </div>
                </div>
                <div className="about__3-content-right">
                  <div className="about__3-shadow">
                    <div className="about__3-content-num">
                      <h2>20</h2>
                      <h6>Project done</h6>
                    </div>
                  </div>
                  <div className="about__3-shadow">
                    <div className="about__3-content-num">
                      <h2>10</h2>
                      <h6>city served</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutDetails;
