import React from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes/constants";

const Gallery = () => {
  return (
    <section className="dp-gallery-area grey-bg-3 pt-120 pb-120">
      <div className="container">
        <div
          className="row align-items-center wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay=".3s"
        >
          <div className="col-xl-7 col-lg-8 col-12">
            <div className="section__title gallery-section-title mb-55">
              <span className="sub-title">explore recent works</span>
              <h2 className="title">Managing Logistics For World’s Best Companies.</h2>
            </div>
          </div>
          <div className="col-xl-5 col-lg-4 col-12">
            <div className="services-two-nav dp-gallery-nav text-end">
              <div className="services-button-prev">
                <i className="fas fa-long-arrow-left" />
              </div>
              <div className="services-button-next">
                <i className="fas fa-long-arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dp-gallery-active swiper-container wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay=".3s"
      >
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="dp-single-gallery">
              <div className="dp-gallery-thumb">
                <img
                  className="img-fluid"
                  src="/assets/img/gallery/gallery-04.jpg"
                  alt="gallery-image"
                />
              </div>
              <div className="dp-gallery-content">
                <div className="dp-gallery-content-text">
                  <h4 className="dp-gallery-title">
                    <NavLink to={ROUTES.PATHS.HOME}>Manufacturing Cost Minimize</NavLink>
                  </h4>
                  <span>logistics</span>
                </div>
                <div className="dp-gallery-link">
                  {/* portfolio-details.html */}
                  <NavLink to={ROUTES.PATHS.HOME}>
                    <i className="fal fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="dp-single-gallery">
              <div className="dp-gallery-thumb">
                <img
                  className="img-fluid"
                  src="/assets/img/gallery/gallery-01.jpg"
                  alt="gallery-image"
                />
              </div>
              <div className="dp-gallery-content">
                <div className="dp-gallery-content-text">
                  <h4 className="dp-gallery-title">
                    <NavLink to={ROUTES.PATHS.HOME}>Rising Online Grocery Sales</NavLink>
                  </h4>
                  <span>logistics</span>
                </div>
                <div className="dp-gallery-link">
                  <NavLink to={ROUTES.PATHS.HOME}>
                    <i className="fal fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="dp-single-gallery">
              <div className="dp-gallery-thumb">
                <img
                  className="img-fluid"
                  src="/assets/img/gallery/gallery-05.jpg"
                  alt="gallery-image"
                />
              </div>
              <div className="dp-gallery-content">
                <div className="dp-gallery-content-text">
                  <h4 className="dp-gallery-title">
                    <NavLink to={ROUTES.PATHS.HOME}>Chemicals Factory Documents</NavLink>
                  </h4>
                  <span>logistics</span>
                </div>
                <div className="dp-gallery-link">
                  <NavLink to={ROUTES.PATHS.HOME}>
                    <i className="fal fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="dp-single-gallery">
              <div className="dp-gallery-thumb">
                <img
                  className="img-fluid"
                  src="/assets/img/gallery/gallery-02.jpg"
                  alt="gallery-image"
                />
              </div>
              <div className="dp-gallery-content">
                <div className="dp-gallery-content-text">
                  <h4 className="dp-gallery-title">
                    <NavLink to={ROUTES.PATHS.HOME}>Lockdown Business Swell</NavLink>
                  </h4>
                  <span>logistics</span>
                </div>
                <div className="dp-gallery-link">
                  <NavLink to={ROUTES.PATHS.HOME}>
                    <i className="fal fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="dp-single-gallery">
              <div className="dp-gallery-thumb">
                <img
                  className="img-fluid"
                  src="/assets/img/gallery/gallery-03.jpg"
                  alt="gallery-image"
                />
              </div>
              <div className="dp-gallery-content">
                <div className="dp-gallery-content-text">
                  <h4 className="dp-gallery-title">
                    <NavLink to={ROUTES.PATHS.HOME}>Warehouse On-Hand Inventory</NavLink>
                  </h4>
                  <span>logistics</span>
                </div>
                <div className="dp-gallery-link">
                  <NavLink to={ROUTES.PATHS.HOME}>
                    <i className="fal fa-long-arrow-right" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
