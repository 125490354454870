import React from "react";
import { NavLink } from "react-router-dom";
import backgroundUrl from "../../helpers/backgroundUrl";
import ROUTES from "../../routes/constants";

const Help = () => {
  return (
    <section
      className="help__cta overlay bg-css overlay-red pt-50 pb-20"
      style={backgroundUrl("/assets/img/cta/help-cta-bg.png")}
    >
      <div className="container">
        <div
          className="row align-items-center wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay=".3s"
        >
          <div className="col-md-8">
            <div className="help__cta-title mb-30">
              <h2>New to cargo service? need help?</h2>
            </div>
          </div>
          <div className="col-md-4">
            <div className="help__cta-btn text-lg-end mb-30">
              <NavLink to={ROUTES.PATHS.GET_A_QUOTE} className="skew-btn">
                get a quote
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
