import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { ELG_PHONE } from "../shared/constants/const";
import BreadcrumbBG from "../ui-containers/layout/breadcrumb-bg/BreadcrumbBG";
import ROUTES from "./constants";

const Services = () => {
  return (
    <div className="page-default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com/services" />
        <meta name="description" content="Services page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Services page, logistics service, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <BreadcrumbBG name="SERVICES" />
      <div className="services__details pt-120 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="services__details-wrapper mb-60">
                <div className="services__details-img mb-35 m-img">
                  <img src="/assets/img/services/services-details-img.jpg" alt="services" />
                </div>
                <div className="service__details-content mb-25">
                  <div className="section__title mb-20">
                    <h3 className="title-sm">Company Air Frieght Overview.</h3>
                  </div>
                  <p className="content-para">
                    These are inbound logistics, outbound logistics, and reverse logistics. The
                    information about these three supply chain directions is essential to know,
                    especially to people inclined in the logistics industry.
                  </p>
                  <p className="mb-25">
                    Logistics is the overseeing of both the inward and outward flow of goods from
                    the manufacturing point to the end user or point of use. The movement of these
                    goods is known as transportation. While some use these terms interchangeably,
                    they are two very unique aspects of the supply chain. Here’s what you need to
                    know about logistics vs. transportation.
                  </p>
                  <div className="row align-items-center overview-list services-overview-space mb-30">
                    <div className="col-sm-6">
                      <div className="overview-list-img w-img mb-25">
                        <img src="/assets/img/services/services-details-img-2.jpg" alt="services" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <ul className="mb-25">
                        <li>Integrated Shipping</li>
                        <li>Logistics Services</li>
                        <li>Product Distribution</li>
                        <li>Product Sustainability</li>
                        <li>Market leaders</li>
                        <li>Maintain their transt</li>
                      </ul>
                    </div>
                  </div>
                  <div className="services_faq">
                    <div className="section__title mb-30">
                      <h3 className="title-sm">FAQ on the Service.</h3>
                    </div>
                    <div className="accordion__wrapper accordion__wrapper-1 pr-20">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              tracking information
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                We can provide real-time tracking information for your shipment,
                                allowing you to stay up-to-date on the location and status of your
                                goods.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              time-sensitive shipments
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                We understand the importance of timely delivery, that's why we offer
                                expedited shipping options such as air freight or express delivery
                                for time-sensitive shipments. Our team will work closely with you to
                                understand your specific needs and develop a plan to meet those
                                needs.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              claims or issues
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                We have a dedicated team that will work with you to resolve any
                                issues or claims that may arise. We will handle all necessary
                                paperwork and communicate with all parties involved to ensure a
                                prompt resolution.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              shipping rates and fees
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                We determine shipping rates and fees based on factors such as the
                                weight, size, and distance of the shipment, as well as any special
                                handling or transportation requirements. Our team will provide you
                                with a quote and work with you to find the most cost-effective
                                solution.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              warehousing and distribution services
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                We offer warehousing and distribution services to help streamline
                                the logistics process and ensure that your goods are delivered on
                                time.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="service-details-sidebar mb-60">
                <div className="sidebar__widget mb-40">
                  <div className="sidebar-title mb-25">
                    <h4>Our Services</h4>
                  </div>
                  <ul className="widget-services">
                    <li>
                      <NavLink to={ROUTES.PATHS.CAR_SHIPPING}>
                        <i className="fas fa-truck"></i> Car Transport
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <i className="fas fa-cogs"></i> Refund Product
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <i className="fas fa-box"></i> Car Transport
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <i className="fas fa-plane"></i> Air Frieght
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <i className="fas fa-ship"></i> Ship Frieght
                      </NavLink>
                    </li>
                    <li>
                      <NavLink>
                        <i className="fas fa-home"></i> Home Delivery
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__widget mb-40">
                  <div className="sidebar-title text-center mb-25">
                    <h4>Get A Free Quote</h4>
                  </div>
                  <div className="text-center mb-4">
                    Get a free quote from us now and experience the best. Contact us today.
                  </div>
                  <div className="text-center">
                    <NavLink to={ROUTES.PATHS.GET_A_QUOTE} className="fill-btn" type="submit">
                      get a quatation
                    </NavLink>
                  </div>
                </div>
                <div className="sidebar__widget sidebar-contact text-center">
                  <img width={60.31} height={54} src="/assets/img/logo/ELG_icon.png" alt="ELG" />
                  <p className="pt-10">
                    Have Additional Questions? <br /> Contact us
                  </p>
                  <h2>
                    <a href={`tel:${ELG_PHONE}`}>{ELG_PHONE}</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
