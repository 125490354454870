import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import BreadcrumbBG from "../ui-containers/layout/breadcrumb-bg/BreadcrumbBG";
import ROUTES from "./constants";

const shippingOptions = [
  {
    img: "door-to-door.png",
    title: "Door-to-Door Car Shipping",
    description:
      "Efficiency Logistics Group offers seamless door-to-door car shipping, ensuring the utmost convenience for your vehicle's transportation needs. Our primary aim is to streamline the process from point A to point B, providing you with a hassle-free experience. With this service, you have the flexibility to choose precise pickup and drop-off points for your vehicle. Whether it's a quaint coffeehouse, your residence, or your workplace, our skilled drivers will accommodate your preferences, always ensuring legality and safety in accessing your chosen locations.",
  },
  {
    img: "open.png",
    title: "Open Auto Transport",
    description:
      "Welcome to Open Auto Transport, where we specialize in efficient and reliable vehicle shipping using open trailers. Your car will typically travel on a spacious two-level trailer, making this method suitable for a wide range of vehicles. If you're transporting a standard model or a used car, open transport is the optimal choice. Our fleet primarily consists of 8 to 10-car haulers, the very same type often seen delivering brand-new vehicles to local dealerships. Count on our skilled drivers and modern carriers to handle your vehicle with the utmost care and professionalism.",
  },
  {
    img: "enclosed.png",
    title: "Enclosed Auto Transport",
    description:
      "Introducing our premium service: Enclosed Auto Transport. It offers top-tier security and protection in a fully sealed trailer, making it the choice for new cars, antiques, and high-value vehicles. Our trailers accommodate up to seven vehicles for personalized attention. Please note, this exclusive service may come with a 30-40% premium over standard open transport. Your peace of mind is our priority at Efficiency Logistics Group.",
  },
  {
    img: "seasonal.png",
    title: "Seasonal Auto Relocation",
    description:
      "Our seasonal vehicle relocation services are meticulously designed to meet and exceed industry standards. Understanding the unique needs and potential stress involved, we've crafted a solution that puts your mind at ease. Our fleet consists of modern carriers, each subjected to regular, stringent mechanical checks. This ensures timely transportation, regardless of weather or road conditions, so you can trust us to deliver your vehicle with precision and care.",
  },
  {
    img: "expedited.png",
    title: "Expedited Car Shipping",
    description:
      "For those seeking a swift, premium solution to car shipping, our Expedited Car Shipping service is tailored just for you. This option prioritizes speed and efficiency, utilizing dedicated carriers to swiftly transport your vehicle from point A to point B. Rest assured, your vehicle is fully insured during the entire expedition. Prior to accepting any loads, our carriers meet stringent insurance requirements, ensuring the utmost protection for your valuable asset. Choose Expedited Car Shipping for a top-tier, accelerated transport experience.",
  },
];

const CarShipping = () => {
  return (
    <div className="page-default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Car-Shipping | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com/car-shipping" />
        <meta name="description" content="Car shipping page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Car shipping, Car Transport, Car, Transportation, logistics service, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <BreadcrumbBG name="CAR SHIPPING" />
      <div className="services__details pt-120 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="services__details-wrapper mb-60">
                <div className="services__details-img mb-35 m-img">
                  <img src="/assets/img/services/car-shipping.jpg" alt="services" />
                </div>
                <div className="service__details-content mb-25">
                  <div className="section__title mb-20">
                    <h3 className="title-sm">Car Shipping Overview</h3>
                  </div>
                  <p className="content-para">
                    Our comprehensive car transport services offer a seamless solution for
                    transporting vehicles of all types, ensuring safety, efficiency, and peace of
                    mind for our valued clients. Our extensive fleet of specialized carriers
                    accommodates various types of vehicles, from compact cars to SUVs, ensuring
                    secure and reliable transportation.
                  </p>
                  <p>
                    We specialize in coordinating complex logistics for classic and exotic cars
                    also.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="service-details-sidebar mb-60">
                <div className="sidebar__widget mb-40">
                  <div className="sidebar-title mb-25">
                    <h4>Our Areas of Expertise</h4>
                  </div>
                  <ul className="widget-services">
                    <li>
                      <NavLink>Military</NavLink>
                    </li>
                    <li>
                      <NavLink>Collection Relocation</NavLink>
                    </li>
                    <li>
                      <NavLink>Racing Events</NavLink>
                    </li>
                    <li>
                      <NavLink>Auto Shows & Events</NavLink>
                    </li>
                    <li>
                      <NavLink>Auto Dealerships</NavLink>
                    </li>
                    <li>
                      <NavLink>Manufacturer Distribution</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__widget mb-40">
                  <div className="sidebar-title text-center mb-25">
                    <h4>Get A Free Quote</h4>
                  </div>
                  <div className="text-center mb-4">
                    Get a free quote from us now and experience the best. Contact us today.
                  </div>
                  <div className="text-center">
                    <NavLink to={ROUTES.PATHS.GET_A_QUOTE} className="fill-btn" type="submit">
                      get a quatation
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section__title mb-30">
            <h4 style={{ fontSize: 24 }} className="">
              Transport options
            </h4>
          </div>
          <div className="row pb-60">
            {shippingOptions.map((item) => (
              <>
                <div className="col-xl-4 col-lg-4 mb-30">
                  <img
                    src={`/assets/img/car-shipping/${item.img}`}
                    alt="car shipping illustration"
                  />
                </div>
                <div className="col-xl-8 col-lg-8">
                  <h4 style={{ color: "#012863" }} className="pb-10">
                    {item.title}
                  </h4>
                  <div>{item.description}</div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarShipping;
