import moment from "moment";
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api";

const RequestAQuote = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [truckLoadType, setTruckLoadType] = useState("");
  const [truckLoadTypeNeeded, setTruckLoadTypeNeeded] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [deliverDate, setDeliverDate] = useState("");
  const [comments, setComments] = useState("");

  // eslint-disable-next-line react/display-name, react/prop-types
  const CustomPickupInput = forwardRef(({ value, onClick }, ref) => (
    <div className="single-input-field" onClick={onClick} ref={ref}>
      <input placeholder="Pickup Date *" value={value} />
    </div>
  ));
  // eslint-disable-next-line react/display-name, react/prop-types
  const CustomDeliverInput = forwardRef(({ value, onClick }, ref) => (
    <div className="single-input-field" onClick={onClick} ref={ref}>
      <input placeholder="Deliver Date *" value={value} />
    </div>
  ));

  const resetRequestForm = () => {
    setName("");
    setPhone("");
    setCompanyName("");
    setEmail("");
    setTruckLoadType("");
    setTruckLoadTypeNeeded("");
    setFromCity("");
    setToCity("");
    setPickupDate("");
    setDeliverDate("");
    setComments("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    /*     console.log(
      "CLICKED",
      name,
      phone,
      email,
      companyName,
      truckLoadType,
      truckLoadTypeNeeded,
      fromCity,
      toCity,
      moment(pickupDate).format("ll"),
      moment(deliverDate).format("ll"),
      comments
    ); */
    if (
      !phone ||
      !truckLoadType ||
      !truckLoadTypeNeeded ||
      !fromCity ||
      !toCity ||
      !pickupDate ||
      !deliverDate
    ) {
      toast.error("Please enter required field!");
      return;
    }

    const response = await api.post("/quote/create", {
      name,
      phone,
      email,
      companyName,
      truckLoadType,
      truckLoadTypeNeeded,
      fromCity,
      toCity,
      pickupDate: moment(pickupDate).format("ll"),
      deliverDate: moment(deliverDate).format("ll"),
      comments,
    });
    if (response.success) {
      toast.success("Request sent successfully! We wil get back to you soon.");
      resetRequestForm();
    }
  };

  return (
    <div>
      <ToastContainer />
      <form method="POST" className="quote-form">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="name"
                value={name}
                type="text"
                placeholder="Your Name"
                onChange={(e) => setName(e.target.value)}
              />
              <i className="fas fa-user"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="phone"
                value={phone}
                type="text"
                placeholder="Phone *"
                onChange={(e) => setPhone(e.target.value)}
              />
              <i className="fas fa-phone-alt"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="company_name"
                value={companyName}
                type="text"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <i className="fas fa-building"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="email"
                value={email}
                type="email"
                placeholder="Email Adress"
                onChange={(e) => setEmail(e.target.value)}
              />
              <i className="fas fa-envelope"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field select">
              <select
                name="truckload_type"
                value={truckLoadType}
                onChange={(e) => {
                  setTruckLoadType(e.target.value);
                }}
              >
                <option selected>Truckload Type *</option>
                <option value="Full Truckload">Full Truckload</option>
                <option value="Less Than Truckload (LTL)">Less Than Truckload (LTL)</option>
              </select>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <select
                name="truckload_type_needed"
                value={truckLoadTypeNeeded}
                onChange={(e) => setTruckLoadTypeNeeded(e.target.value)}
              >
                <option selected>Truck Type needed *</option>
                <option value="Dry Van">Dry Van</option>
                <option value="Flat bed">Flat bed</option>
                <option value="Refrigerated">Refrigerated</option>
                <option value="Intermodal">Intermodal</option>
                <option value="Expedited">Expedited</option>
                <option value="Drayage">Drayage</option>
              </select>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="from_city"
                value={fromCity}
                type="text"
                placeholder="From City / State *"
                onChange={(e) => setFromCity(e.target.value)}
              />
              <i className="fas fa-car-building"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="single-input-field">
              <input
                name="to_city"
                value={toCity}
                type="text"
                placeholder="To City / State *"
                onChange={(e) => setToCity(e.target.value)}
              />
              <i className="fas fa-car-building"></i>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6" style={{ zIndex: "10" }}>
            <DatePicker
              selected={pickupDate}
              onChange={(date) => setPickupDate(date)}
              customInput={<CustomPickupInput />}
              dateFormat="MM-dd-yyyy"
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6" style={{ zIndex: "10" }}>
            <DatePicker
              selected={deliverDate}
              onChange={(date) => setDeliverDate(date)}
              customInput={<CustomDeliverInput />}
              dateFormat="MM-dd-yyyy"
            />
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="single-input-field textarea">
              <textarea
                rows="10"
                cols="10"
                placeholder="Comments"
                name="comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              ></textarea>
              <i className="fas fa-edit"></i>
            </div>
          </div>
          <div className="col-xxl-12 col-xl-12">
            <button onClick={handleSubmit} type="submit" className="fill-btn clip-btn">
              Send Request
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RequestAQuote;
