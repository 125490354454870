import React from "react";
import { NavLink } from "react-router-dom";
import ROUTES from "../../routes/constants";

const AboutServices = () => {
  return (
    <section
      className="services__3 grey-bg-4 pt-120 pb-90 wow fadeInUp"
      data-wow-duration="1.5s"
      data-wow-delay=".3s"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section__title mb-55 text-center">
              <span className="sub-title">services</span>
              <h2 className="title">what we do</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>01</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="flaticon-boat" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>ocean transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>02</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="flaticon-plane" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>Air transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>03</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="fas fa-car" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.CAR_SHIPPING}>car transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>04</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="flaticon-frontal-truck" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>truck transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>05</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="flaticon-train" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>train transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>06</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="flaticon-box" />
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>package transport</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="services__3-item mb-30">
              <div className="services__3-item-num">
                <h3>07</h3>
              </div>
              <div className="services__3-item-icon">
                <i className="far fa-truck-moving"></i>
              </div>
              <h3 className="services__3-item-title">
                <NavLink to={ROUTES.PATHS.SERVICES}>Cargo Van Transportation</NavLink>
              </h3>
              <p className="services__3-item-text">
                The quality data, best network building uptime, fastest output from uur team jackle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutServices;
