import React from "react";
import PropTypes from "prop-types";
import backgroundUrl from "../../../helpers/backgroundUrl";

const BreadcrumbBG = (props) => {
  const { name } = props;

  return (
    <section
      className="page-title-area breadcrumb-spacing"
      style={backgroundUrl("/assets/img/breadcrumb/breadcrumb-bg.png")}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-9">
            <div className="page-title-wrapper text-center">
              <h3 className="page-title mb-25">{name}</h3>
              <div className="breadcrumb-menu">
                <nav aria-label="Breadcrumbs" className="breadcrumb-trail breadcrumbs">
                  <ul className="trail-items">
                    <li className="trail-item trail-begin">
                      <a href="index.html">
                        <span>Home</span>
                      </a>
                    </li>
                    <li className="trail-item trail-end">
                      <span>{name}</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BreadcrumbBG.propTypes = {
  name: PropTypes.string,
};

export default BreadcrumbBG;
