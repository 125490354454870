import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ELG_EMAIL, ELG_PHONE } from "../shared/constants/const";
import BreadcrumbBG from "../ui-containers/layout/breadcrumb-bg/BreadcrumbBG";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!phone && !email) {
      toast.info("Please enter your contact info.");
      return;
    }
    // console.log("CLICKED", name, email, phone, message);

    const response = await api.post("/contact", {
      name,
      email,
      phone,
      message,
    });
    if (response.success) {
      toast.success("Message sent successfully! We wil get back to you soon.");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="page-default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com/contact" />
        <meta name="description" content="Contact page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Contact page, contact information, logistics service, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <ToastContainer />
      <BreadcrumbBG name="CONTACT" />
      <div
        className="contact-area contact--area pt-120 pb-110 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay=".3s"
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-6 col-lg-5">
              <div className="contact--wrapper mb-60">
                <div className="section__title mb-45">
                  <span className="sub-title">contact with us</span>
                  <h2 className="title">Speak with our consultant</h2>
                </div>
                <div className="contact-info mr-20">
                  <div className="single-contact-info d-flex align-items-center">
                    <div className="contact-info-icon">
                      <NavLink>
                        <i className="flaticon-telephone-call"></i>
                      </NavLink>
                    </div>
                    <div className="contact-info-text">
                      <span>Call us now</span>
                      <h5>
                        <a href={`tel:${ELG_PHONE}`}>{ELG_PHONE}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="single-contact-info d-flex align-items-center">
                    <div className="contact-info-icon">
                      <NavLink>
                        <i className="flaticon-envelope"></i>
                      </NavLink>
                    </div>
                    <div className="contact-info-text">
                      <span>send email</span>
                      <h5>
                        <a href={`mailto:${ELG_EMAIL}`}>{ELG_EMAIL}</a>
                      </h5>
                    </div>
                  </div>
                  <div className="single-contact-info d-flex align-items-center">
                    <div className="contact-info-icon">
                      <NavLink>
                        <i className="flaticon-pin"></i>
                      </NavLink>
                    </div>
                    <div className="contact-info-text">
                      <span>visit office</span>
                      <h5>
                        <a href="https://www.google.com/maps/search/12%2FA,+New+Boston+Hall/@42.5515021,-79.7879305,7z/data=!3m1!4b1">
                          Houston, Texas, united states
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-6 col-lg-7">
              <div className="contact-form mb-60">
                <form id="contact-form">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="single-input-field">
                        <input
                          name="name"
                          value={name}
                          type="text"
                          placeholder="Your Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <i className="fas fa-user"></i>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="single-input-field">
                        <input
                          name="email"
                          value={email}
                          type="email"
                          placeholder="Email Adress"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <i className="fas fa-envelope"></i>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="single-input-field">
                        <input
                          name="phone"
                          value={phone}
                          type="text"
                          placeholder="Phone"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <i className="fas fa-phone-alt"></i>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                      <div className="single-input-field textarea">
                        <textarea
                          rows="10"
                          cols="10"
                          placeholder="Write Massage"
                          name="massage"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <i className="fas fa-edit"></i>
                      </div>
                    </div>
                    <div className="col-xxl-12 col-xl-12">
                      <button onClick={handleSubmit} type="submit" className="fill-btn clip-btn">
                        Send a message
                      </button>
                    </div>
                  </div>
                </form>
                <p className="ajax-response"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
