import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { ELG_ONBOARDING } from "../shared/constants/const";
import "../styles/quote.scss";
import RequestAQuote from "../ui-containers/get-a-quote/RequestAQuote";
import Track from "../ui-containers/get-a-quote/Track";

const GetAQuote = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="page-default default-text">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Request a quote | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://localhost:3000/request-a-quote" />
        <meta name="description" content="Request a quote page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="Request a quote page, apply for quote, track and trace, logistics service, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <img
        width={"100%"}
        height={"100%"}
        src="/assets/img/getaquote/1.2.jpg"
        alt="GetAQuote Banner"
      />
      <div className="pb-120">
        <div className="container mt-60">
          <div className="contact-panel tab-margin">
            <div className="contact-types">
              <div
                onClick={() => handleTabClick(0)}
                className={`button quote-btn cursor-pointer ${activeTab === 0 && "active"}`}
              >
                <span>request a quote</span>
              </div>
              <div
                onClick={() => handleTabClick(1)}
                className={`button quote-btn cursor-pointer ${activeTab === 1 && "active"}`}
              >
                <span>track &amp; trace</span>
              </div>
            </div>
            <div className="contact-card">
              <div className="row">
                <div></div>
                <div className={activeTab === 0 ? "col-lg-8" : "col-lg-12"}>
                  {activeTab === 1 ? <Track /> : <RequestAQuote />}
                </div>
                {activeTab === 0 && (
                  <div className="d-none d-sm-block col-lg-4">
                    <div className="apply-card widget widget-reservation">
                      <img src="/assets/img/getaquote/1.jpg" alt="Background Image" />
                      <div className="widget-content">
                        <h5>take your carreer to next level</h5>
                        <p>
                          We pride ourselves on providing the best transport & shipping services
                          available all over the world and there is no one comes close to us.{" "}
                        </p>
                        <a
                          href={ELG_ONBOARDING}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="cursor-pointer flat-btn"
                        >
                          BECOME A CARRIER
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetAQuote;
