import React from "react";

const Track = () => {
  return (
    <div className="col-lg-8">
      <div>TRACK & TRACE SECTION WILL BE UPDATED SOON ...</div>
    </div>
  );
};

export default Track;
