import React from "react";
import { Helmet } from "react-helmet";
import AboutApproach from "../ui-containers/about-approach/AboutApproach";
import AboutDetails from "../ui-containers/about-details/AboutDetails";
import AboutMission from "../ui-containers/about-mission/AboutMission";
import AboutServices from "../ui-containers/about-services/AboutServices";
import BreadcrumbBG from "../ui-containers/layout/breadcrumb-bg/BreadcrumbBG";

const About = () => {
  return (
    <div className="page-default">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Efficiency Logistics Group</title>
        <link rel="canonical" href="http://www.efficiencyfreight.com/about" />
        <meta name="description" content="About page for Efficiency Logistics Group" />
        <meta
          name="keywords"
          content="About page, logistic group, efficiency logistic group, freight broker, logistic service provider"
        />
      </Helmet>
      <BreadcrumbBG name="ABOUT" />
      <div>
        <AboutDetails />
        <AboutServices />
        <AboutApproach />
        <AboutMission />
      </div>
    </div>
  );
};

export default About;
