import React from "react";
import styles from "./header.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import ROUTES from "../../../routes/constants";
import { ELG_EMAIL, ELG_ONBOARDING, ELG_PHONE, NavItems } from "../../../shared/constants/const";

export const Header = () => {
  const location = useLocation();

  return (
    <header>
      <div
        id="header-sticky"
        className={`header__two menu-sticky ${
          location.pathname === "/" && "header__transparent  "
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-6">
              <div className="logo">
                <NavLink to={ROUTES.PATHS.HOME}>
                  <img width={149.16} height={48} src="/assets/img/logo/ELG_Logo.png" alt="ELG" />
                </NavLink>
                {/* <a href="index.html">
                  <img src="assets/img/logo/logo.png" alt />
                </a> */}
              </div>
            </div>
            <div className="col-xl-7 d-none d-xl-block">
              <div className="main-menu main-menu-2 t-right">
                <nav id="mobile-menu">
                  <ul>
                    {NavItems.map((item) => (
                      <li key={item.link}>
                        <NavLink
                          style={({ isActive }) => ({
                            color: isActive ? "var(--clr-common-color-red-2)" : "",
                          })}
                          to={item.link}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    ))}

                    <li className="menu-item-has-children">
                      <NavLink>Carriers</NavLink>
                      <ul className="sub-menu">
                        <li>
                          <a href={ELG_ONBOARDING} target="_blank" rel="noreferrer noopener">
                            Become a Carrier
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header__two-btn t-right d-block d-lg-none text-end">
                <NavLink className="fill-btn clip-btn" to={ROUTES.PATHS.GET_A_QUOTE}>
                  get a quote
                </NavLink>
              </div>
            </div>
            <div className="col-xl-3 col-6">
              <div className="header__two-btn lh-1 t-right d-none d-xl-block">
                <NavLink className="fill-btn clip-btn" to={ROUTES.PATHS.GET_A_QUOTE}>
                  get a quote
                </NavLink>
              </div>
              <div className="text-end d-xl-none d-block">
                <div className="header__toggle-btn sidebar-toggle-btn">
                  <div className="header__bar">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar__area">
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button className="sidebar__close-btn" id="sidebar__close-btn">
              <i className="fal fa-times" />
            </button>
          </div>
          <div className="sidebar__content">
            <div className="sidebar__logo mb-40">
              <NavLink to={ROUTES.PATHS.HOME}>
                <img width={149.16} height={48} src="/assets/img/logo/ELG_Logo.png" alt="Logo" />
              </NavLink>
              {/* <a href="index.html">
                <img src="assets/img/logo/logo.png" alt="logo" />
              </a> */}
            </div>
            <div className="sidebar__search mb-25">
              <form action="#">
                <div className="single-input-field">
                  <input name="name" type="text" placeholder="Search Here" />
                  <i className="fas fa-search" />
                </div>
              </form>
            </div>
            <nav className={`mb-10 fix ${styles.mobile_nav}`}>
              <ul>
                {NavItems.map((item) => (
                  <li key={item.link}>
                    <NavLink
                      style={({ isActive }) => ({
                        color: isActive ? "var(--clr-common-color-red-2)" : "",
                      })}
                      to={item.link}
                      className="mobile_nav__link"
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}

                {/* menu-item-has-children */}
                <li>
                  <a href={ELG_ONBOARDING} target="_blank" rel="noreferrer noopener">
                    Become a Carrier
                  </a>
                </li>
              </ul>
            </nav>
            <div className="sidebar__contact mt-30 mb-30">
              <div className="sidebar__info fix">
                <div className="sidebar__info-item">
                  <div className="sidebar__info-icon">
                    <i className="flaticon-telephone-call" />
                  </div>
                  <div className="sidebar__info-text">
                    <span>Call us now</span>
                    <h5>
                      <a href={`tel:${ELG_PHONE}`}>{ELG_PHONE}</a>
                    </h5>
                  </div>
                </div>
                <div className="sidebar__info-item">
                  <div className="sidebar__info-icon">
                    <i className="flaticon-envelope" />
                  </div>
                  <div className="sidebar__info-text">
                    <span>Email now</span>
                    <h5>
                      <a href={`mailto:${ELG_EMAIL}`}>{ELG_EMAIL}</a>
                    </h5>
                  </div>
                </div>
                <div className="sidebar__info-item">
                  <div className="sidebar__info-icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="sidebar__info-text">
                    <span>Houston</span>
                    <h5>Texas, united states</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar__social">
              <ul>
                <li>
                  <a href="">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href="">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="body-overlay" />
    </header>
  );
};

export default Header;
