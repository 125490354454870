import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./routes/error-page";
import About from "./routes/about";
import Services from "./routes/services";
import Contact from "./routes/contact";
import Home from "./routes/home";
import ROUTES from "./routes/constants";
import Blogs from "./routes/blogs";
import GetAQuote from "./routes/getaquote";
import CarShipping from "./routes/car-shipping";

const router = createBrowserRouter([
  {
    path: ROUTES.PATHS.HOME,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES.PATHS.ABOUT,
        element: <About />,
      },
      {
        path: ROUTES.PATHS.CAR_SHIPPING,
        element: <CarShipping />,
      },
      {
        path: ROUTES.PATHS.SERVICES,
        element: <Services />,
      },
      {
        path: ROUTES.PATHS.CONTACT,
        element: <Contact />,
      },
      {
        path: ROUTES.PATHS.BLOG_DETAILS,
        element: <Blogs />,
      },
      {
        path: ROUTES.PATHS.GET_A_QUOTE,
        element: <GetAQuote />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
