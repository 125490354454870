import React from "react";
import { NavLink } from "react-router-dom";
import backgroundUrl from "../../../helpers/backgroundUrl";
import ROUTES from "../../../routes/constants";
import { ELG_EMAIL, ELG_PHONE } from "../../../shared/constants/const";

const Footer = () => {
  return (
    <footer>
      <div className="footer-area footer__padd-1 p-relative footer-area1-bg pt-150 pb-50">
        <div className="call__cta position p-absolute">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="call__cta-wrapper call__cta-padd overlay overlay-red clip-box bg-css"
                  style={backgroundUrl("/assets/img/cta/call-cat-bg-1.png")}
                >
                  <div className="call__cta-content">
                    <h6 className="call__cta-content-small-title">Call us now</h6>
                    <h3 className="call__cta-content-title">
                      <a href={`tel:${ELG_PHONE}`}> {ELG_PHONE}</a>
                    </h3>
                  </div>
                  <div className="call__cta-icon">
                    <i className="flaticon-telephone-call" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="call__cta-wrapper call__cta-padd overlay overlay-red clip-box bg-css"
                  style={backgroundUrl("/assets/img/cta/call-cta-bg-2.png")}
                >
                  <div className="call__cta-content">
                    <h6 className="call__cta-content-small-title">Email us now</h6>
                    <h3 className="call__cta-content-title">
                      <a href={`mailto:${ELG_EMAIL}`}>
                        <span
                          className="__cf_email__"
                          data-cfemail="6d04030b022d1a080f000c0401430e0200"
                        >
                          {ELG_EMAIL}
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div className="call__cta-icon">
                    <i className="flaticon-envelope" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer2-widget footer2-widget1 mb-50 pr-20">
                <div className="footer-widget-title">
                  <h4>about us</h4>
                </div>
                <p>
                  Efficiency Logistics Group is a representative logistics operator providing full
                  range of service in the sphere of customs clearance transportation worldwide for
                  any cargo
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer2-widget footer2-widget2 mb-50 pl-30">
                <div className="footer-widget-title">
                  <h4>other pages</h4>
                </div>
                <div className="footer-widget-link">
                  <ul>
                    <li>
                      <NavLink to={ROUTES.PATHS.ABOUT}>About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.SERVICES}>Services</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to={ROUTES.PATHS.HOME} href="">
                        Our Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.HOME}>Pricing</NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.HOME}>FAQ &amp; Ans</NavLink>
                    </li> */}
                    <li>
                      <NavLink to={ROUTES.PATHS.CAR_SHIPPING} href="">
                        Car Shipping
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.CONTACT} href="">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  <ul>
                    {/* <li>
                      <NavLink to={ROUTES.PATHS.HOME}>Careers</NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.HOME}>News &amp; Insights</NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.HOME}>Refund Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to={ROUTES.PATHS.HOME}>Terms &amp; Conditions</NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer2-widget footer2-widget3 mb-50 pl-55">
                <div className="footer-widget-title">
                  <h4>our services</h4>
                </div>
                <ul className="footer-widget-link-2">
                  <li>
                    <i className="fas fa-truck" />
                    <NavLink to={ROUTES.PATHS.CAR_SHIPPING}>Car Transport</NavLink>
                  </li>
                  <li>
                    <i className="fas fa-cogs" />
                    <NavLink to={ROUTES.PATHS.SERVICES}>Refund Product</NavLink>
                  </li>
                  <li>
                    <i className="fas fa-plane" />
                    <NavLink to={ROUTES.PATHS.SERVICES}>Air Frieght</NavLink>
                  </li>
                  <li>
                    <i className="fas fa-ship" />
                    <NavLink to={ROUTES.PATHS.SERVICES}>Ship Frieght</NavLink>
                  </li>
                  <li>
                    <i className="fas fa-home" />
                    <NavLink to={ROUTES.PATHS.SERVICES}>Home Delivery</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget-title">
                <h4>Connect</h4>
              </div>
              <div className="footer-social-link">
                <ul>
                  <li>
                    <NavLink to={ROUTES.PATHS.HOME}>
                      <i className="fab fa-facebook-f" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={ROUTES.PATHS.HOME}>
                      <i className="fab fa-twitter" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={ROUTES.PATHS.HOME}>
                      <i className="fab fa-behance" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={ROUTES.PATHS.HOME}>
                      <i className="fab fa-youtube" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
