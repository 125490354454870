import React from "react";

const FunFact = () => {
  return (
    <section className="dp-funfact-area pt-120 pb-120">
      <div className="container">
        <div
          className="dp-funfactor-grid wow fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay=".3s"
        >
          <div className="dp-funfact-wrapper mb-30">
            <div className="dp-funfact-icon">
              <i className="fal fa-globe" />
            </div>
            <div className="dp-funfact-content">
              <h3 className="counter">19300</h3>
              <p>Worldwide Clients</p>
            </div>
          </div>
          <div className="dp-funfact-wrapper mb-30">
            <div className="dp-funfact-icon">
              <i className="fal fa-users" />
            </div>
            <div className="dp-funfact-content">
              <h3 className="counter">17083</h3>
              <p>Company Staffs</p>
            </div>
          </div>
          <div className="dp-funfact-wrapper mb-30">
            <div className="dp-funfact-icon">
              <i className="fal fa-smile" />
            </div>
            <div className="dp-funfact-content">
              <h3 className="counter">17227</h3>
              <p>Satisfied Clients</p>
            </div>
          </div>
          <div className="dp-funfact-wrapper mb-30">
            <div className="dp-funfact-icon">
              <i className="fal fa-truck" />
            </div>
            <div className="dp-funfact-content">
              <h3 className="counter">18907</h3>
              <p>Successful Delivery</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
