import React from "react";
import { NavLink } from "react-router-dom";
import backgroundUrl from "../../helpers/backgroundUrl";
import ROUTES from "../../routes/constants";

const Services = () => {
  return (
    <section
      id="services__area-2"
      className="services__area-2 fix grey-bg-2 pt-120 pb-120"
      style={backgroundUrl("/assets/img/services/services-bg.png")}
    >
      <div
        className="services__section-area wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay=".3s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="services__section">
                <div className="section__title mb-95">
                  <span className="sub-title">services</span>
                  <h2 className="title">what we do</h2>
                </div>
                <div className="services-two-nav">
                  <div className="services-button-prev">
                    <i className="fas fa-long-arrow-left" />
                  </div>
                  <div className="services-button-next">
                    <i className="fas fa-long-arrow-right" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="services-two">
                <div className="swiper-container services-two-active">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="services__item text-center">
                        <div className="services__item-icon mb-35">
                          <i className="flaticon-boat" />
                        </div>
                        <div className="services__item-content">
                          <NavLink to={ROUTES.PATHS.SERVICES}>
                            <h3>
                              ocean <br /> transportation
                            </h3>
                          </NavLink>
                          <p>The quality data, best network building uptime, fastest output</p>
                        </div>
                        <div className="services__item-shape"></div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services__item text-center">
                        <div className="services__item-icon mb-35">
                          <i className="flaticon-plane" />
                        </div>
                        <div className="services__item-content">
                          <NavLink to={ROUTES.PATHS.SERVICES}>
                            <h3>
                              airways <br /> transportation
                            </h3>
                          </NavLink>
                          <p>The quality data, best network building uptime, fastest output</p>
                        </div>
                        <div className="services__item-shape"></div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services__item text-center">
                        <div className="services__item-icon mb-35">
                          <i className="fas fa-car"></i>
                        </div>
                        <div className="services__item-content">
                          <NavLink to={ROUTES.PATHS.CAR_SHIPPING}>
                            <h3>
                              car <br /> transportation
                            </h3>
                          </NavLink>
                          <p>The quality data, best network building uptime, fastest output</p>
                        </div>
                        <div className="services__item-shape"></div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services__item text-center">
                        <div className="services__item-icon mb-35">
                          <i className="flaticon-frontal-truck" />
                        </div>
                        <div className="services__item-content">
                          <NavLink to={ROUTES.PATHS.SERVICES}>
                            <h3>
                              road <br /> transportation
                            </h3>
                          </NavLink>
                          <p>The quality data, best network building uptime, fastest output</p>
                        </div>
                        <div className="services__item-shape"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
