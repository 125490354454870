import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import loadjs from "loadjs";
import Header from "../ui-containers/layout/header/Header";
import Footer from "../ui-containers/layout/footer/Footer";
import ScrollToTop from "../helpers/ScrollToTop";
import ROUTES from "./constants";

export default function Root() {
  const location = useLocation();
  useEffect(() => {
    if ([ROUTES.PATHS.HOME].includes(location.pathname)) loadjs("./assets/js/main.js", () => {});
  }, [location.pathname]);

  return (
    <div>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
}
